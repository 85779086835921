import React from 'react';
import {
  HelveticaRegular112,
  HelveticaRegular56,
} from '@/components/styles/fonts';

import { Container } from './styles';

const VideoTitle = ({ number, title, description }) => (
  <Container>
    <HelveticaRegular56>{number}</HelveticaRegular56>
    <HelveticaRegular112>{title}</HelveticaRegular112>
    <p>{description}</p>
  </Container>
);

export default VideoTitle;

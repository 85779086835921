import React, { useState, useEffect, useRef, useCallback } from 'react';

import theme from '@/styles/theme';
import AudioControls from '@/components/atoms/AudioControls';
import { Container } from './styles';

const AudioPlayer = ({ src, playerType, setPlayerType = () => {} }) => {
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(new Audio(src));
  const intervalRef = useRef();

  const { duration } = audioRef.current;
  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : '0%';

  const barColor = `linear-gradient(90deg, ${theme.colors.white} ${currentPercentage}, ${theme.colors.gray[700]} ${currentPercentage})`;

  const startTimer = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!audioRef.current.ended) {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  }, []);

  const onScrub = (value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    audioRef.current = new Audio(src);
    if (playerType === 'audio') {
      setIsPlaying(src);
    }

    return () => {
      setIsPlaying(false);
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, [playerType, src]);

  useEffect(() => {
    if (isPlaying) {
      setPlayerType('audio');
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, setPlayerType, startTimer]);

  return (
    <Container>
      <AudioControls isPlaying={isPlaying} onPlayPauseClick={setIsPlaying} />
      <input
        type="range"
        value={trackProgress}
        step="1"
        min="0"
        max={duration || `${duration}`}
        className="progress"
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{ background: barColor }}
      />
    </Container>
  );
};

export default AudioPlayer;

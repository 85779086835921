import { toast } from 'react-toastify';

import { api } from '@/config/axios';

export async function getPodcasts() {
  const { data } = await api.get('/podcasts');
  return data;
}

export async function postPodcasts(podcasts) {
  try {
    const { data } = await api.post('/podcasts', podcasts);
    return data;
  } catch (e) {
    toast.error('Falha ao subir arquivo');
    return Promise.reject();
  }
}

export async function uploadFile({ file, filepath, submitUpload }) {
  try {
    const { type } = file;
    const params = { filepath, filetype: type };
    const options = {
      headers: {
        'Content-Type': type,
      },
    };

    const { data: url } = await api.get('/podcasts/upload', {
      params,
    });

    const { data } = await submitUpload({
      route: url,
      method: 'put',
      data: file,
      options,
    });

    return data;
  } catch (e) {
    if (e.code === 'ERR_CANCELED') {
      return Promise.reject(e);
    }
    toast.error('Falha ao subir arquivo');
    return Promise.reject();
  }
}

import styled from 'styled-components';

import {
  helveticaRegular20,
  helveticaRegular32,
} from '@/components/styles/fonts';

export const Container = styled.article`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  p {
    ${helveticaRegular32}

    @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
      ${helveticaRegular20}
      margin-top: 12px
    }
  }
`;

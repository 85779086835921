import styled from 'styled-components';
import {
  helveticaRegular112,
  helveticaRegular32,
  helveticaRegular56,
} from '@/components/styles/fonts';

export const TextWrapper = styled.div`
  margin: 2rem 0 0 2rem;

  @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
    margin: 7% 0 0 10%;
  }

  @media (min-width: ${({ theme }) => theme.maxWidth}) {
    margin: 95px 0 0 138px;
  }

  h1 {
    ${helveticaRegular112}
    margin-bottom: 1rem;
  }

  h2 {
    ${helveticaRegular32}

    @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
      ${helveticaRegular56}
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperLogo = styled.header`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 0;

  svg {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
    margin-right: 7px;

    svg {
      display: block;
    }
  }
`;

export const Square = styled.div`
  background-color: ${({ theme }) => theme.colors.redLogo};
  display: block;
  height: 4rem;
  max-height: 42px;
  max-width: 42px;
  width: 4rem;

  @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
    display: none;
  }
`;

import { css, createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
${({ theme }) => css`
  @font-face {
    font-family: 'HelveticaNowDisplay';
    src: local('HelveticaNowDisplay'),
      url('./assets/fonts/HelveticaNowDisplay-Regular.otf') format('opentype');
  }

  html {
    scroll-behavior: smooth;
  }

  * {
    box-sizing: border-box;
    font-family: 'HelveticaNowDisplay', Helvetica, sans-serif;
    margin: 0;
    padding: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
  }

  html,
  body,
  input,
  button {
    font-size: 2.3vmin;

    @media (min-width: ${theme.mobileMaxWidth}) {
      font-size: 16px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 100;
  }

  button {
    cursor: pointer;
  }
`}


`;

export default GlobalStyle;

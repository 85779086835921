import React, { useRef, useCallback, useEffect, useState } from 'react';

import Video from '@/components/atoms/Video';

const VideoPlayer = ({ src, playerType, setPlayerType = () => {} }) => {
  const playerRef = useRef(null);
  const [videoJsOptions, setVideoOptions] = useState();

  useEffect(() => {
    if (src) {
      setVideoOptions({
        autoplay: !!playerType && playerType === 'video',
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src,
            type: 'video/mp4',
          },
        ],
      });
    }
  }, [playerType, src]);

  useEffect(() => {
    if (playerType === 'audio') {
      playerRef.current?.pause();
    }
  }, [playerType]);

  const handlePlayerReady = useCallback(
    (player) => {
      playerRef.current = player;

      player.on('playing', () => {
        setPlayerType('video');
      });
    },
    [setPlayerType],
  );

  return videoJsOptions ? (
    <Video options={videoJsOptions} onReady={handlePlayerReady} />
  ) : (
    <></>
  );
};

export default VideoPlayer;

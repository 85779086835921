import styled from 'styled-components';

export const Container = styled.main`
  margin: -50px 10% 10%;

  .add {
    margin: 2rem 0;
  }
`;

export const List = styled.section`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(400px, auto));
  justify-content: space-between;
`;

import React from 'react';
import { TextWrapper } from './styles';

function TextHeader({ subtitle }) {
  return (
    <TextWrapper>
      <h1>More Human Future</h1>
      <h2>{subtitle}</h2>
    </TextWrapper>
  );
}

export default TextHeader;

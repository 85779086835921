import { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';

import { api } from '@/config/axios';

export const useUpload = () => {
  const [progress, setProgress] = useState(0);
  const [source, setSource] = useState();

  function submitUpload({ route, method = 'post', data, options = {} }) {
    const config = {
      ...options,
      onUploadProgress(progressEvent) {
        const progressCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setProgress(progressCompleted);
      },
      cancelToken: source?.token,
    };
    return api[method](route, data, { ...config });
  }

  const cancelUpload = useCallback(() => {
    source?.cancel();
    const cancel = Axios.CancelToken;
    setSource(cancel.source);
    setProgress(0);
  }, [source]);

  useEffect(() => {
    const cancel = Axios.CancelToken;
    setSource(cancel.source);

    return () => {
      setProgress(0);
      if (cancel.source.cancel) cancel.source?.cancel();
    };
  }, []);

  const isLoading = progress > 0 && progress < 100;

  return { submitUpload, cancelUpload, progress, isLoading };
};

import React from 'react';

import ExternalLink from '@/components/atoms/ExternalLink';
import { Container, LinksWrapper, Copyright } from './styles';

function Footer() {
  return (
    <Container>
      <LinksWrapper>
        <ExternalLink
          label="RGA.COM" //
          href="https://rga.com"
        />
        <ExternalLink
          label="Twitter" //
          href="https://twitter.com/RGA"
        />
        <ExternalLink
          label="Instagram"
          href="https://www.instagram.com/rgabydesign"
        />
        <ExternalLink
          label="LinkedIn"
          href="https://www.linkedin.com/company/r-ga/"
        />
      </LinksWrapper>
      <Copyright />
    </Container>
  );
}

export default Footer;

import styled from 'styled-components';

import { ReactComponent as CopyrightIcon } from '@/assets/icons/Copyright.svg';

export const Container = styled.footer`
  padding-top: 80px;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const LinksWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 16px 48px;

  a {
    margin-bottom: 40px;
  }

  a + a {
    margin-bottom: 12px;
  }
`;

export const Copyright = styled(CopyrightIcon)`
  height: 100%;
  margin: 0;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
    margin: 32px 0 16px;
  }
`;

import styled from 'styled-components';

export const Container = styled.article`
  margin-block: 123px;
`;

export const VideosWrapper = styled.section`
  display: grid;
  grid-gap: 2%;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-auto-flow: row;
  justify-content: start;
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
    grid-template-columns: repeat(auto-fit, 217px);
  }
`;

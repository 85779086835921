import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Arrow } from '@/assets/icons/Arrow.svg';
import { Container } from './styles';

const AnchorPodcasts = ({ number, title, selected }) => (
  <Container selected={selected}>
    <Link to={`/${title}`}>
      <h4>{number}</h4>
      <p>{title}</p>
      <Arrow />
    </Link>
  </Container>
);

export default AnchorPodcasts;

import React from 'react';

import { HelveticaRegular56 } from '@/components/styles/fonts';
import AnchorPodcasts from '@/components/atoms/AnchorPodcasts';
import { Container, VideosWrapper } from './styles';

const VideoList = ({ videos, selected }) => (
  <Container>
    <HelveticaRegular56>Veja/Escute outros podcases</HelveticaRegular56>
    <VideosWrapper>
      {videos.map(({ title }, index) => (
        <AnchorPodcasts
          key={title}
          number={index + 1}
          title={title}
          selected={selected?.title === title}
        />
      ))}
    </VideosWrapper>
  </Container>
);

export default VideoList;

import styled from 'styled-components';

import { helveticaRegular20 } from './fonts';

export const ButtonIcon = styled.button`
  ${helveticaRegular20}
  align-items: center;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.black};
  border: none;
  border-radius: 4px;
  color: ${({ theme, textColor }) => textColor || theme.colors.white};
  display: flex;
  gap: 0.2rem;
  padding: 0.5rem;
`;

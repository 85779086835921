/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { StyledModal } from './styles';

const Modal = forwardRef(({ children, isOpen: initialOpen, ...rest }, ref) => {
  const [isOpen, setIsOpen] = useState(!!initialOpen);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  const getNewChildren = () =>
    React.isValidElement(children) ? (
      React.cloneElement(children, {
        isOpen,
        toggleModal,
      })
    ) : (
      <div />
    );

  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={() => toggleModal()}
      onEscapeKeydown={() => toggleModal()}
      {...rest}
    >
      {getNewChildren()}
    </StyledModal>
  );
});

Modal.defaultProps = { isOpen: false, alignSelf: '' };
export default Modal;

import React from 'react';

import Header from '@/components/organisms/Header';
import Podcasts from '@/components/organisms/Podcasts';
import Footer from '@/components/organisms/Footer';

function Home() {
  return (
    <>
      <Header subtitle="Conheça as ideias da R/GA que humanizam tecnologias em benefício das pessoas." />
      <Podcasts />
      <Footer />
    </>
  );
}

export default Home;

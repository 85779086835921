import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 20px;
  padding: 2rem;
  width: 50vw;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  label {
    display: flex;
    flex-direction: column;
    margin-block: 1rem;

    input,
    textarea {
      border: 1px solid ${({ theme }) => theme.colors.gray[200]};
      border-radius: 4px;
      padding: 0.5rem;
    }
  }

  button {
    align-self: flex-end;
  }
`;

import React, { useState } from 'react';
import { FiEdit, FiPlus } from 'react-icons/fi';

import { ButtonIcon } from '@/components/styles/buttons';
import theme from '@/styles/theme';
import { Container, Form } from './styles';

const FormPodcast = ({ handleEdit, podcast }) => {
  const [title, setTitle] = useState(podcast?.title || '');
  const [description, setDescription] = useState(podcast?.description || '');
  return (
    <Container>
      <h1> {podcast ? 'Edite:' : 'Novo Podcast'}</h1>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleEdit({ description, title });
        }}
      >
        <label htmlFor="title">
          Título
          <input
            id="title"
            type="text"
            value={title}
            name="title"
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
        <label htmlFor="description">
          Descrição
          <textarea
            id="description"
            value={description}
            name="description"
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <ButtonIcon color={theme.colors.gray[300]} type="submit">
          {podcast ? (
            <>
              Atualizar <FiEdit />
            </>
          ) : (
            <>
              Criar <FiPlus />
            </>
          )}
        </ButtonIcon>
      </Form>
    </Container>
  );
};

export default FormPodcast;

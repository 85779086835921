import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPodcasts } from '@/services/api';

import VideoTitle from '@/components/molecules/VideoTitle';
import VideoPlayer from '@/components/molecules/VideoPlayer';
import VideoList from '@/components/molecules/VideoList';
import AudioPlayer from '@/components/molecules/AudioPlayer';
import { HelveticaRegular56 } from '@/components/styles/fonts';

import { Container } from './styles';

const Podcasts = () => {
  const [selected, setSelected] = useState({});
  const [videos, setVideos] = useState([]);
  const [playerType, setPlayerType] = useState('VIDEO');
  const { video } = useParams();

  useEffect(() => {
    getPodcasts().then((data) => {
      const parse = data.map((dt, index) => ({
        ...dt,
        sequence: index + 1,
        number: (index + 1).toString().padStart(2, '0'),
        videoUrl:
          dt.videoUrl && process.env.REACT_APP_VIDEO_BASE_URL + dt.videoUrl,
        audioUrl:
          dt.audioUrl && process.env.REACT_APP_AUDIO_BASE_URL + dt.audioUrl,
      }));

      setVideos(parse);
    });
  }, []);

  useEffect(() => {
    if (videos.length) {
      setSelected(videos.find(({ title }) => title === video) || videos[0]);
    }
  }, [videos, video]);

  const { number, sequence, title, description, videoUrl, audioUrl } = selected;

  return (
    <Container>
      <VideoTitle number={number} title={title} description={description} />
      <VideoPlayer
        playerType={playerType}
        setPlayerType={setPlayerType}
        src={videoUrl}
      />
      {audioUrl && (
        <>
          <HelveticaRegular56 className="episode">
            Episódio {sequence}
          </HelveticaRegular56>
          <AudioPlayer
            playerType={playerType}
            setPlayerType={setPlayerType}
            src={audioUrl}
          />
        </>
      )}
      {videos.length > 1 && <VideoList videos={videos} selected={selected} />}
    </Container>
  );
};

export default Podcasts;

import { css } from 'styled-components';
import Modal from 'styled-react-modal';

const modalStyle = css`
  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `};
`;

export const StyledModal = Modal.styled`${modalStyle}`;

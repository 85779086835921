import React from 'react';

import theme from '@/styles/theme';
import { ButtonIcon } from '@/components/styles/buttons';
import { HelveticaRegular20 } from '@/components/styles/fonts';
import { Container, Progress } from './styles';

function ProgressBar({ isLoading, progress, cancelUpload }) {
  return (
    <Container>
      {isLoading && (
        <>
          <Progress value={progress} max="100" />
          <HelveticaRegular20>{progress} %</HelveticaRegular20>
          {cancelUpload && (
            <ButtonIcon
              bgColor={theme.colors.red}
              onClick={cancelUpload}
              type="ButtonIcon"
            >
              Parar
            </ButtonIcon>
          )}
        </>
      )}
    </Container>
  );
}

export default ProgressBar;

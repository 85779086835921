import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { ButtonIcon } from '@/components/styles/buttons';
import theme from '@/styles/theme';
import { Container, Content, Form } from './styles';

function Login({ setIsLogged }) {
  const [admin, setAdmin] = useState('');
  const [password, setPassword] = useState('');

  const validatingData = () => {
    if (admin === 'admin' && password === 'yN9shW5p') {
      setIsLogged(true);
    } else {
      toast.error('Admin não encontrado');
    }
  };
  return (
    <Container>
      <Content>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validatingData();
          }}
        >
          <label htmlFor="title">
            Administrador
            <input
              id="admin"
              type="text"
              value={admin}
              name="admin"
              onChange={(e) => setAdmin(e.target.value)}
            />
          </label>
          <label htmlFor="title">
            Senha
            <input
              id="password"
              type="password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <ButtonIcon color={theme.colors.gray[300]} type="submit">
            Logar
          </ButtonIcon>
        </Form>
      </Content>
    </Container>
  );
}

export default Login;

import styled from 'styled-components';

export const Container = styled.main`
  margin: 8rem 2rem 3rem;

  @media (min-width: ${({ theme }) => theme.mobileMaxWidth}) {
    margin: -40px 10% 20px;
  }

  @media (min-width: ${({ theme }) => theme.maxWidth}) {
    margin: -40px 138px 20px;
  }

  .episode {
    display: block;
    padding-block: 3rem 1.5rem;
  }
`;

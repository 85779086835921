import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Home from '@/components/pages/Home';
import Admin from '@/components/pages/Admin';

const Routes = () => (
  <BrowserRouter basename="/MoreHumanFuture">
    <Switch>
      <Route exact path="/admin" component={Admin} />
      <Route exact path="/:video?" component={Home} />
    </Switch>
  </BrowserRouter>
);

export default Routes;

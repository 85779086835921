import React, { useEffect, useState, useRef } from 'react';
import { FiPlus } from 'react-icons/fi';

import { getPodcasts, postPodcasts } from '@/services/api';
import Modal from '@/components/templates/Modal';
import { ButtonIcon } from '@/components/styles/buttons';
import CardPodcast from '@/components/organisms/CardPodcast';
import FormPodcast from '@/components/organisms/FormPodcast';
import { Container, List } from './styles';

function Manager() {
  const [podcasts, setPodcasts] = useState([]);
  const endListRef = useRef([]);
  const modalRef = useRef();

  useEffect(() => {
    getPodcasts().then((data) => setPodcasts(data));
  }, []);

  async function onUpdate({ index, name, type, list = podcasts }) {
    if (name && type === 'video') {
      podcasts[index].videoUrl = name;
    }
    if (name && type === 'audio') {
      podcasts[index].audioUrl = name;
    }
    const data = await postPodcasts(list);
    setPodcasts(data);
  }

  async function handleCreate(data) {
    const CARD_SIZE = 900;
    podcasts.push(data);
    await onUpdate({ list: podcasts });
    window.scrollTo(0, endListRef.current.offsetTop - CARD_SIZE);
    modalRef.current.toggleModal();
  }

  return (
    <Container>
      <ButtonIcon
        className="add"
        type="button"
        onClick={() => modalRef.current.toggleModal()}
      >
        <FiPlus />
        Adicionar
      </ButtonIcon>
      <List>
        {podcasts.map((podcast, index, arr) => (
          <CardPodcast
            key={podcast.title}
            podcast={podcast}
            podcasts={arr}
            onSuccess={(data) => onUpdate({ index, ...data })}
          />
        ))}
      </List>
      <div ref={endListRef} />
      <Modal ref={modalRef}>
        <FormPodcast handleEdit={(data) => handleCreate(data)} />
      </Modal>
    </Container>
  );
}

export default Manager;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from '@/assets/icons/Arrow.svg';
import { A } from './styles';

function ExternalLink({ label, href, ...rest }) {
  return (
    <A {...rest} href={href} title={label} target="_blank" rel="noreferrer">
      {label}
      <Arrow />
    </A>
  );
}

ExternalLink.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
};

ExternalLink.defaultProps = {
  label: '',
  href: '',
};

export default ExternalLink;

import styled from 'styled-components';

export const A = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding-bottom: 2px;
  text-decoration: none;

  svg {
    margin-left: 8px;
    height: 18px;
  }

  &:hover {
    position: relative;

    &::after {
      border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`;

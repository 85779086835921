import React from 'react';
import { ModalProvider } from 'styled-react-modal';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './routes';
import GlobalStyle from './styles/global';
import theme from './styles/theme';

const App = () => (
  <ThemeProvider theme={theme}>
    <ModalProvider>
      <GlobalStyle />
      <ToastContainer />
      <Routes />
    </ModalProvider>
  </ThemeProvider>
);

export default App;

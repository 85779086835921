import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  height: 3rem;

  button {
    padding: 0.25rem;
  }

  span {
    text-align: center;
    width: 50px;
  }
`;

export const Progress = styled.progress`
  border-radius: 7px;
  flex: 1;
  height: 1rem;

  ::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.colors.gray[200]};
    border-radius: 1rem;
  }
  ::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.red};
  }
`;

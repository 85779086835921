const commonColors = {
  black: '#000000',
  white: '#ffffff',
  red: '#ff0000',
  redLogo: '#ed1b30',
  boxShadow: '#00000033',
  gray: {
    50: '#f7f7f7',
    100: '#e7e7e7',
    200: '#d8d8d8',
    300: '#c4c4c4',
    400: '#a9a9a9',
    500: '#818185',
    700: '#393939',
  },
};

const commonFont = {
  fontStyle: 'normal',
  fontSize: '1rem',
};

const commonTheme = {
  maxWidth: '1440px',
  mobileMaxWidth: '768px',
  mobileMaxWidth1: '600px',
  mobileMaxWidth2: '412px',
};

export const light = {
  ...commonTheme,
  fonts: { ...commonFont },
  colors: {
    ...commonColors,
    text: '#000000',
    background: '#ffffff',
  },
};

export const dark = {
  ...commonTheme,
  fonts: { ...commonFont },
  colors: {
    ...commonColors,
    text: '#ffffff',
    background: '#000',
  },
};

const theme = light;

export default theme;

import React, { useRef, useEffect } from 'react';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';

import play from '@/assets/icons/Play.svg';
import { Video, Container } from './styles';

export const VideoJS = ({ options, onReady }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { pathname } = useLocation();

  const isAdmin = pathname.includes('admin');

  useEffect(() => {
    let player = playerRef.current;

    if (!player) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      player = videojs(videoElement, options, () => {
        player.log('player is ready');
        if (onReady) onReady(player);
      });

      playerRef.current = player;
    } else {
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [onReady, options]);

  useEffect(() => {
    const player = playerRef.current;

    const buttons = Array.from(
      document.getElementsByClassName('vjs-big-play-button'),
    );

    buttons.forEach((button) => {
      const count = button.childElementCount;
      const DEFAULT_BUTTON_CHILDREN_COUNT = 2;

      if (count === DEFAULT_BUTTON_CHILDREN_COUNT) {
        const svg = document.createElement('img');
        svg.src = play;
        button.appendChild(svg);
      }
    });

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <Container isAdmin={isAdmin} className="data-vjs-player">
      <Video
        ref={videoRef}
        className="video-js vjs-theme-fantasy vjs-big-play-centered"
      />
    </Container>
  );
};

export default VideoJS;

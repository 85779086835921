import React from 'react';

import { ReactComponent as RgaSign } from '@/assets/icons/RgaSign.svg';
import TextHeader from '@/components/atoms/HeaderText';
import { Container, WrapperLogo, Square } from './styles';

function Header({ subtitle }) {
  return (
    <Container>
      <TextHeader subtitle={subtitle} />
      <WrapperLogo>
        <Square />
        <RgaSign />
      </WrapperLogo>
    </Container>
  );
}

export default Header;

import React, { useRef } from 'react';
import { FiEdit, FiXOctagon } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import theme from '@/styles/theme';
import Modal from '@/components/templates/Modal';
import VideoPlayer from '@/components/molecules/VideoPlayer';
import AudioPlayer from '@/components/molecules/AudioPlayer';
import InputPodcast from '@/components/molecules/InputPodcast';
import { ButtonIcon } from '@/components/styles/buttons';
import {
  HelveticaRegular20,
  HelveticaRegular56,
} from '@/components/styles/fonts';

import FormPodcast from '../FormPodcast';
import { Container, ButtonsWrapper } from './styles';

const CardPodcast = ({ podcast, podcasts, onSuccess }) => {
  const modalRef = useRef();
  const { title, description, videoUrl, audioUrl } = podcast;
  const video = process.env.REACT_APP_VIDEO_BASE_URL + videoUrl;
  const audio = process.env.REACT_APP_AUDIO_BASE_URL + audioUrl;

  async function handleEdit(data) {
    const index = podcasts.findIndex((pod) => pod === podcast);
    const podcastList = podcasts;
    podcastList[index] = { ...podcast, ...data };
    toast.success(`${title} foi editado com sucesso`);
    onSuccess({ list: podcastList });
  }

  function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?!',
      text: `Isso irá apagar ${title}`,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Voltar',
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return;
      const newList = [...podcasts.filter((pod) => pod !== podcast)];
      onSuccess({ list: newList });
    });
  }

  return (
    <Container>
      <HelveticaRegular56>{title}</HelveticaRegular56>
      <HelveticaRegular20>{description}</HelveticaRegular20>
      <VideoPlayer src={video} />
      <InputPodcast
        name="video"
        accept="video/*"
        id={`video${video}`}
        placeholder="Escolha um vídeo"
        title={title}
        onSuccess={(name) => onSuccess({ name, type: 'video' })}
      />
      <AudioPlayer src={audio} />
      <InputPodcast
        name="audio"
        accept="audio/*"
        id={`audio${audio}`}
        placeholder="Escolha um áudio"
        title={title}
        onSuccess={(name) => onSuccess({ name, type: 'audio' })}
      />
      <ButtonsWrapper>
        <ButtonIcon
          type="button"
          onClick={() => modalRef.current.toggleModal()}
        >
          Editar <FiEdit />
        </ButtonIcon>
        <ButtonIcon
          bgColor={theme.colors.redLogo}
          type="button"
          onClick={() => handleDelete()}
        >
          Deletar <FiXOctagon />
        </ButtonIcon>
      </ButtonsWrapper>
      <Modal ref={modalRef}>
        <FormPodcast
          podcast={podcast}
          handleEdit={(data) => handleEdit(data)}
        />
      </Modal>
    </Container>
  );
};

export default CardPodcast;

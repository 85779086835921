import styled, { css } from 'styled-components';
import {
  helveticaRegular20,
  helveticaRegular56,
} from '@/components/styles/fonts';

export const Container = styled.div`
  ${({ theme, selected }) => css`
    border: 1px solid ${theme.colors.black};
    height: 120px;
    padding: 0 13px 9px;
    position: relative;
    width: 100%;

    a {
      color: ${theme.colors.black};
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;

      h4 {
        ${helveticaRegular56}
        font-size: 56px;
      }

      p {
        ${helveticaRegular20}
        font-size: 20px;
      }

      svg {
        display: none;
      }
    }

    ${selected
      ? css`
          border-color: ${theme.colors.gray[400]};

          a {
            color: ${theme.colors.gray[400]};
          }
        `
      : css`
          &:hover {
            background-color: ${theme.colors.black};

            a {
              color: ${theme.colors.white};

              svg {
                display: block;
                margin: 14px;
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
        `}
  `}
`;

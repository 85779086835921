/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { toast } from 'react-toastify';

import { useUpload } from '@/hooks/useAxiosUpload';
import { uploadFile } from '@/services/api';

import ProgressBar from '@/components/atoms/ProgressBar';
import { Container } from './styles';

const InputPodcast = ({
  name,
  title,
  accept,
  id,
  placeholder,
  onSuccess,
  ...rest
}) => {
  const { isLoading, progress, submitUpload, cancelUpload } = useUpload();

  async function handleUpload(e) {
    let filepath = '';
    const { files, name: type } = e.target;
    const file = Array.from(files)[0];

    if (!file) {
      return;
    }

    const filenameArr = file.name.split('.');
    const ext = filenameArr.pop();
    const filename = filenameArr.join();

    const newName = `/${filename.replace(/\W+/g, '').toLowerCase()}.${ext}`;

    if (type === 'audio') {
      filepath = `media/audios${newName}`;
    }
    if (type === 'video') {
      filepath = `media/videos${newName}`;
    }

    try {
      await uploadFile({ file, filepath, submitUpload });
      await onSuccess(newName);
      toast.success(`Foi criado com sucesso ${name}: ${title}`);
    } catch (err) {
      if (err.code === 'ERR_CANCELED') {
        toast.info(`Foi cancelado ${name}: ${title}`);
        return;
      }
      toast.error('Algo deu errado, tente novamente.');
    }
  }

  return (
    <Container>
      <label htmlFor={id}>
        {placeholder}
        <input
          type="file"
          name={name}
          accept={accept}
          id={id}
          placeholder={placeholder}
          disabled={isLoading}
          onChange={(e) => handleUpload(e, title)}
          multiple={false}
          {...rest}
        />
      </label>

      <ProgressBar
        cancelUpload={cancelUpload}
        isLoading={isLoading}
        progress={progress}
      />
    </Container>
  );
};

export default InputPodcast;

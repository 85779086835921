import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.boxShadow};
  display: flex;
  flex-direction: column;
  min-width: 400px;
  padding: 1rem;
  transition: 0.3s;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

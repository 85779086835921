import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  padding: 1.5rem;
  width: 100%;

  input[type='range'] {
    ${({ barColor }) => barColor}
    -webkit-appearance: none;
    border-radius: 8px;
    cursor: pointer;
    height: 5px;
    margin-left: 2rem;
    transition: background 0.2s ease;
    width: 100%;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background: ${({ theme }) => theme.colors.red};
      border-radius: 100%;
      cursor: pointer;
      height: 2rem;
      max-height: 20px;
      max-width: 20px;
      width: 2rem;
    }
  }
`;

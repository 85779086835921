import React from 'react';

import { ReactComponent as Play } from '@/assets/icons/Play.svg';
import { ReactComponent as Pause } from '@/assets/icons/Pause.svg';
import { Button } from './styles';

const AudioControls = ({ isPlaying, onPlayPauseClick, onPrevClick }) => (
  <>
    {isPlaying ? (
      <Button
        type="button"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        <Pause />
      </Button>
    ) : (
      <Button
        type="button"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        <Play />
      </Button>
    )}
  </>
);

export default AudioControls;

import React, { useState } from 'react';

import Header from '@/components/organisms/Header';
import Footer from '@/components/organisms/Footer';
import Manager from '@/components/templates/Manager';
import Login from '@/components/organisms/Login';

function Home() {
  const [isLogged, setIsLogged] = useState(false);

  return (
    <>
      <Header subtitle="Admin" />
      {!isLogged ? <Login setIsLogged={setIsLogged} /> : <Manager />}
      <Footer />
    </>
  );
}

export default Home;

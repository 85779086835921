import styled, { css } from 'styled-components';

import '@videojs/themes/dist/fantasy/index.css';

export const Container = styled.div`
  ${({ theme }) => css`
    .vjs-theme-fantasy .vjs-play-progress,
    .vjs-theme-fantasy .vjs-play-progress:before {
      background-color: ${theme.colors.red};
    }

    .vjs-theme-fantasy {
      --vjs-theme-fantasy--primary: ${theme.colors.red};
    }
  `}

  .vjs-big-play-button {
    ${({ isAdmin }) =>
      !isAdmin
        ? css`
            span {
              display: none;
            }

            img {
              height: 6.25rem;
              width: 6.25rem;
            }

            &:hover {
              opacity: 0.8;
              transition: 1s;
            }
          `
        : css`
            img {
              display: none;
            }
          `}
`;

export const Video = styled.video`
  margin-top: 4rem;
  width: 100%;
`;

import styled, { css } from 'styled-components';

export const textStyles = css`
  font: ${({
    theme: {
      fonts: { fontFamily, fontStyle },
    },
  }) => ` ${fontStyle} ${fontFamily}`};
  text-transform: ${({ theme }) => theme.fonts.textTransform};
`;

export const helveticaRegular112 = css`
  ${textStyles}
  font-size: 7rem;
  line-height: 6.5rem;
  letter-spacing: -0.1375rem;
`;

export const helveticaRegular56 = css`
  ${textStyles}
  font-size: 3.5rem;
  line-height: 120%;
`;

export const helveticaRegular32 = css`
  ${textStyles}
  font-size: 2rem;
  line-height: 120%;
`;

export const helveticaRegular20 = css`
  ${textStyles}
  font-size: 1.25rem;
  line-height: 120%;
`;

export const helveticaRegular16 = css`
  ${textStyles}
  font-size: 1rem;
  line-height: 120%;
`;

/**
 * Components
 */

export const HelveticaRegular112 = styled.span`
  ${helveticaRegular112}
`;

export const HelveticaRegular56 = styled.span`
  ${helveticaRegular56}
`;

export const HelveticaRegular20 = styled.span`
  ${helveticaRegular20}
`;
